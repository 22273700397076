import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import "./index.scss";

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
