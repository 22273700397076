

import { Heading } from '@aws-amplify/ui-react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFeatherAlt } from '@fortawesome/free-solid-svg-icons'

function Header() {
    return (
        <div className="header">
            <FontAwesomeIcon icon={faFeatherAlt} className="header-icon"/>
            <Heading level={3} className='swis-heading'>SWIS</Heading>
            <sub className='heading-superscript'>BETA</sub>
        </div>
    );
}

export default Header;