import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import QRCode from "qrcode.react";
import { Button, Card, Heading, Loader, View } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";



export function Home() {
  const [qr, setQr] = useState('');
  const [user, setUser] = useState('');
  const[disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Auth.currentAuthenticatedUser()
    // .then((c) => {
    //     console.log(c);
    //     API.get("Heimdall", "/identity", {})
    //     .then(b => console.log(b))
    //     .catch(e => console.log(e));
    // })
    // .catch((e) => {
    //   console.log(e);
    // });
      Auth.currentSession().then(u => {
      console.log(u);
      // API.get("Heimdall", "/identity", {})
      // .then(b => {
      //   console.log(b);
      //   const name = b['userDetails']['name'];
      //   setUser(name);
      // }).catch(e => {
      //   console.log(e);
      //   setUser(u.getIdToken().payload['email'].replace('@amazon.com', '').toUpperCase());
      // });
      setUser(u.getIdToken().payload['email'].replace('@amazon.com', '').toUpperCase());
      setQr(u.getRefreshToken().getToken()+"," + u.getAccessToken().payload['username']);
    }).catch((c) => {
      console.log(c);
      navigate("/", { replace: true });
    });
  });

  const signOut = () => {
    setDisabled(true);
    setQr('');
    Auth.signOut();
  }

    return (
      <div className="wrapper">
      <div className="dummy" />
      <Card variation="outlined" className="custom-qr-card">
      
      { qr && <View className="qr-code">
         <Heading level={6}>Hi {user}! Scan this QR code in SWIS to login</Heading>
         
        <QRCode value={qr} size={256}></QRCode> 

        <Button
          isFullWidth={true}
          type="submit"
          variation="primary"
          isLoading={false}
          onClick={signOut}
          disabled={disabled}
        >
          Sign Out
        </Button>
        </View>
        }
         {!qr && <Loader className='my-loader' fr={15}/> }
      </Card>
     
            

      <div className="dummy"></div>
      </div>
    );
}

export default Home;
