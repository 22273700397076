import React from "react";
import { Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import Basic from "./Basic";
import "../config/amplify";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Home";
import Header from "./Header";


export class App extends React.Component<{}, any> {
  constructor(props?: any) {
    super(props);
  }

  componentDidMount(): void {
    Auth.signOut();   
  }

  federate =  () => {
    this.setState({pending: true});
    Auth.federatedSignIn({ customProvider: "EZRME-Federate-IdentityProvider" });
  };


  render() {
    return (
      <div className="main-wrapper">
      <Header></Header>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Basic/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
      </Routes>
      </BrowserRouter>
      </div>
    );
  }
}

export default App;
