
import Amplify from "aws-amplify";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const signInUrl = isLocalhost ? 'http://localhost:4321/home': 'https://d2wkgqkcb7rdgz.cloudfront.net/home';
const signOutUrl = isLocalhost ? 'http://localhost:4321/': 'https://d2wkgqkcb7rdgz.cloudfront.net/'

Amplify.configure({
    Auth: {
      userPoolId: 'us-east-1_GQDSOy3if',
      identityPoolId: 'us-east-1:ec579fa9-3301-478d-84f5-26e4cb2700d5',
      userPoolWebClientId: '496t66fm3aogpd3tht61g4e1qn',
      region: 'us-east-1',
      mandatorySignIn: true,
      oauth: {
          domain: 'ezrme-alpha.auth.us-east-1.amazoncognito.com',
          redirectSignIn: signInUrl, // this will have to be replaced with the website url when you deploy
          redirectSignOut: signOutUrl, // this will have to be replaced with the website url when you deploy
          scope: ["email", "openid", "aws.cognito.signin.user.admin"],
          responseType: "code"
      }
    },
    API: {
      endpoints: [
          {
              name: "Heimdall",
              endpoint: "https://api.heimdall.alpha.rme.logistics.a2z.com"
          }
      ]
  }
    // authenticationFlowType: "CUSTOM_AUTH"
  })