import React, { useState } from 'react';
import { Formik } from 'formik';
import {
    Card,
    PasswordField,
    Button,
    TextField,
    Heading,
    Loader,
  } from "@aws-amplify/ui-react";
import { Auth, API } from 'aws-amplify';
import { useNavigate } from "react-router-dom";


function continueAuthentication(password: string = null): void {
    Auth.currentAuthenticatedUser()
      .then((c) => {
          console.log(c);
          API.get("Heimdall", "/identity", {})
          .then(b => console.log(b))
          .catch(e => console.log(e));
      })
      .catch((e) => {
        console.log(e);
      });
  }


 function Basic() {
    const [error, setError] = useState('');
    const [ssoLoading, setSsoLoading] = useState(false);
    const navigate = useNavigate();
    const handleSSO = () => {
        setError('');
        setSsoLoading(true);
        Auth.federatedSignIn({ customProvider: "EZRME-Federate-IdentityProvider" })
        // .then(() => setSsoLoading(false))
        .catch((e) => {
            setError(e.message);
            setSsoLoading(false);
        });
    }
    return (
        <div className="wrapper">
            <div className="dummy" />
            <Card variation="outlined" className="custom-card">
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validate={values => {
                        const errors: any = {};
                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    } }
                    onSubmit={(values, { setSubmitting }) => {
                        setError('');
                        setTimeout(async () => { 
                            await Auth.signIn({
                                username: values.email,
                                password: values.password
                            }).then(() => {
                                navigate("/home", { replace: true });
                            })
                            .catch((e) => {
                                setError(e.message);
                            }).finally(() => setSubmitting(false));
                            
                        }, 0);
                    } }
                >
                    {({
                        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                    }) => (
                        <div className='form-wrapper'>
                            <form onSubmit={handleSubmit} className='form-grid'>
                                <TextField
                                    type="mail"
                                    name="email"
                                    label="Email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    gap="10px"
                                    value={values.email} />
                                <div className="wrap-error">
                                {errors.email && touched.email && errors.email}
                                </div>
                                <PasswordField
                                    type="password"
                                    label="Password"
                                    enterKeyHint="done"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    gap="10px"
                                    value={values.password} />
                                <div className="wrap-error">
                                {errors.password && touched.password && errors.password}
                                </div>
                                <Button type="submit" disabled={isSubmitting || ssoLoading}>
                                    Sign in using Email
                                </Button>

                            </form>

                            <Heading className='or-heading' level={6}>OR</Heading>

                            <Button className='sso-button' type="submit" disabled={isSubmitting || ssoLoading} onClick={handleSSO}>
                                Sign in using SSO
                            </Button>

                            { (isSubmitting || ssoLoading) && <Loader className='loader' size="large" fr={5}/> }
                            {error && <Heading className='error-heading' level={6}>{error}</Heading>}
                        </div>
                    )}
                </Formik>
            </Card>
            <div className="dummy"></div>
        </div>
    );
}
 
 export default Basic;